<template>
  <div class="inner">
    <div class="card">
      <!-- 名字 -->
      <div class="name">
        <div class="line1">{{ $t('enroll.Name') }}</div>
        <van-field
          v-model="name"
          :placeholder="$t('enroll.The_length_should_not_exceed_10_characters')"
          maxlength="10"
        />
      </div>
      <!-- 简介 -->
      <div class="introduction">
        <div class="line1">{{ $t('enroll.Inroduction') }}</div>
        <div class="place-font">
          <quill-editor
            :content="content"
            :options="editorOption"
            @change="updateData($event)"
            class="is-editor"
          />
          <!-- <vue-html5-editor
            :content="content"
            :height="300"
            @change="updateData"
          ></vue-html5-editor> -->
          <span class="limit">{{ limitStr.length }}/800</span>
        </div>
      </div>
      <!-- 标识图片 -->
      <div class="pic">
        <div style="display:flex; height:114px;">
          <div class="title">
            {{ $t('enroll.Logo_Image') }}
          </div>
          <div>
            <van-uploader
              v-model="fileList"
              preview-size="110px"
              :max-count="1"
              :after-read="afterRead"
              max-size="5242880"
              @oversize="oversize"
              @delete="deletePic"
              style="overflow:hidden;"
            />
          </div>
        </div>
        <div class="tip">*{{ $t('enroll.image_size') }}</div>
        <div class="tip">*{{ $t('enroll.isnopic') }}</div>
        <div style="margin-top:10px;">
          <van-checkbox
            v-model="checkedPic"
            shape="square"
            @click="isSelfPic"
            >{{ $t('enroll.usePic') }}</van-checkbox
          >
        </div>
      </div>
      <!-- 抵押yta数量 -->
      <div class="yta-num">
        <span class="one"
          >{{ $t('enroll.The_number_of_YTA_in_stake') }} &nbsp;</span
        >
        <span class="two"> {{ is_node ? 0 : 1000000 }}</span>
      </div>
      <!-- 报名时间 -->
      <div class="time">
        <div class="line1">*{{ $t('enroll.The_registration_time_is') }}</div>
        <div class="line2">
          {{ timeKind(timeObj.start_time, 1) }}&nbsp;
          {{ timeKind(timeObj.start_time, 2) }}~{{
            timeKind(timeObj.end_time, 1)
          }}&nbsp; {{ timeKind(timeObj.end_time, 2) }}
        </div>
      </div>
      <!-- 报名 -->
      <div class="btn">
        <div class="msg" @click="goOn">{{ $t('enroll.Register') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// 工具栏配置
const toolbarOptions = []
import { deposit } from '@/api/swap'
import { Toast } from 'vant'
import { upload, getInitData, getConfig, isSuperNode } from '@/api/enroll'
import { signUp } from '@/utils/index'
import { timeType } from '@/utils/index'
export default {
  data() {
    return {
      limitStr: '',
      name: '',
      content: '',
      showplace: true,
      fileList: [],
      ytaValue: '1000000',
      picUrl: '',
      userInfo: {},
      timeObj: {},
      checkedPic: false,
      editorOption: {
        //  富文本编辑器配置
        modules: {
          //工具栏定义的
          toolbar: toolbarOptions,
        },
        //主题
        theme: 'snow',
        placeholder: '请输入正文',
      },
      is_node: 0,
    }
  },
  methods: {
    // 删除图片
    deletePic() {
      this.picUrl = ''
      this.fileList = []
      this.checkedPic = false
    },
    // 使用默认图片
    isSelfPic() {
      if (this.checkedPic) {
        this.fileList = []
        this.picUrl = this.timeObj.default_url
        this.fileList.push({ url: this.timeObj.default_url })
      } else {
        this.fileList = []
        this.picUrl = ''
      }
    },
    // 获取用户初始因袭
    async getUserInit() {
      if (!this.$store.state.account.name) {
        Toast(this.$t('tip.your_main_account_not_found'))
        return
      }
      let res = await getInitData({ account: this.$store.state.account.name })
      if (res.code === 0) {
        console.log(res)
        // 1  需要转账
        // 0  不需要转账
        this.userInfo = res.data
      }
    },
    // 获取首页统计项
    async getConfigMsg() {
      let res = await getConfig()
      if (res.code == 0) {
        this.timeObj = res.data
      }
    },
    // 超过图片大小
    oversize() {
      Toast.fail(this.$t('enroll.Image_size_limit_exceeded'))
    },
    updateData(e) {
      this.content = e.html
      let str = e.html.replace(/<\/?.+?\/?>/g, '')
      str = str.replace(/[ ]|[&nbsp;]/g, '')
      this.limitStr = str
      if (str.length > 800) {
        Toast.fail(
          this.$t('enroll.The_length_should_not_exceed_800_characters')
        )
      }
    },
    async goOn() {
      if (!this.name) {
        Toast(this.$t('enroll.Please_enter_your_name'))
        return
      } else if (!this.content || this.content == '<br>') {
        Toast(this.$t('enroll.Please_fill_in_the_introduction'))
        return
      } else if (!this.picUrl) {
        Toast(this.$t('enroll.Please_upload_the_logo_image'))
        return
      }
      if (
        this.is_node != 1 &&
        this.ytaValue > this.ytaBalance &&
        this.userInfo.is_trx == 1
      ) {
        return Toast.fail(this.$t('enroll.balance_is_less'))
      }
      if (!this.$store.state.account.name) {
        Toast(this.$t('tip.your_main_account_not_found'))
        return
      }
      try {
        let res = {}
        if (this.is_node != 1 && this.userInfo.is_trx == 1) {
          res = await deposit(this.account, this.ytaValue)
        } else if (this.is_node != 1) {
          res.transaction_id = '已经转过'
        }
        if (this.is_node == 1) {
          res.transaction_id = '00000'
        }
        if (res.transaction_id && res.transaction_id.length > 0) {
          let responce = await signUp(
            this.name,
            this.content,
            this.picUrl,
            this.ytaValue,
            res.transaction_id
          )
          console.log(responce)
          await this.$store.dispatch('getBalance')
          Toast.success(this.$t('enroll.Successful_Registration'))
          this.getUserInit()
          this.$router.push({ path: 'governance' })
        } else {
          Toast.fail(this.$t('enroll.Registration_Failed'))
        }
      } catch (err) {
        await this.$store.dispatch('getBalance')
        Toast.fail(err.msg)
        this.getUserInit()
      }
    },
    async afterRead(file) {
      // 上传图片到服务器
      console.log(file)
      let fd = new FormData()
      fd.append('file', file.file)
      let res = await upload(fd)
      if (res.code == 0) {
        Toast.success(this.$t('enroll.Upload_Successful'))
        this.picUrl = res.url
      } else {
        Toast.fail(this.$t('enroll.file_failed'))
        this.fileList = []
      }
    },
    timeKind(time, num) {
      return timeType(time, num)
    },
    async isSuperNode() {
      let res = await isSuperNode({ account: this.$store.state.account.name })
      this.is_node = res.data.is_node
      console.log(res)
    },
  },

  created() {
    this.getUserInit()
    this.getConfigMsg()
    this.isSuperNode()
    this.editorOption.placeholder = this.$t(
      'enroll.The_length_should_not_exceed_800_characters'
    )
  },
  computed: {},
}
</script>

<style lang="less" scoped>
/deep/ input::-webkit-input-placeholder {
  color: #666666;
}
/deep/ .is-editor {
  height: 300px;
  .ql-toolbar {
    height: 0.01px;
    // padding: 0px;
  }
}
/deep/ .vue-html5-editor > .toolbar > .dashboard {
  z-index: 999;
}
/deep/ .van-cell {
  padding: 0px;
}
/deep/ .van-field__control {
  font-size: 14px;
}
/deep/ .van-uploader {
  width: 110px;
  height: 110px;
}
.inner {
  background: #eef2f6;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 17px;
  padding-bottom: 35px;
  .card {
    padding: 24px 10px 16px 10px;
    box-sizing: border-box;
    width: 93.333%;
    margin: 0 auto;
    background-color: #fff;
    .name {
      box-sizing: border-box;
      padding-left: 9px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      .line1 {
        font-size: 14px;
        color: #181a33;
        line-height: 16px;
        margin-bottom: 7px;
        font-weight: bold;
      }
    }
    .introduction {
      padding-top: 17px;
      padding-left: 9px;
      position: relative;
      padding-bottom: 22px;
      border-bottom: 1px solid #eeeeee;

      .limit {
        position: absolute;
        top: 41px;
        right: 10px;
        font-size: 14px;
        color: #9a9fa4;
      }
      .line1 {
        font-size: 14px;
        color: #181a33;
        margin-bottom: 6px;
        font-weight: bold;
      }
    }
    .pic {
      padding-top: 20px;
      box-sizing: border-box;
      padding-left: 9px;
      padding-bottom: 11px;
      border-bottom: 1px solid #eeeeee;

      .title {
        font-size: 14px;
        color: #181a33;
        margin-right: 13px;
        font-weight: bold;
      }
      .tip {
        margin-top: 12px;
        font-size: 13px;
        color: #df2828;
      }
    }
    .yta-num {
      padding-left: 9px;
      box-sizing: border-box;
      padding-top: 12px;
      padding-bottom: 14px;
      border-bottom: 1px solid #eeeeee;
      .one {
        font-size: 14px;
        color: #181a33;
        font-weight: bold;
      }
      .two {
        color: #0073ee;
        font-size: 14px;
        margin-left: 8px;
      }
    }
    .time {
      padding-top: 9px;
      padding-left: 9px;
      font-size: 14px;
      color: #777789;
      .line1 {
        font-weight: bold;
      }
      .line2 {
        margin-top: 5px;
      }
    }
    .btn {
      padding-left: 9px;
      padding-right: 9px;
      margin-top: 39px;
      .msg {
        width: 100%;
        background: #0073ee;
        border-radius: 6px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        color: #ffffff;
        font-size: 15px;
      }
    }
  }
}
</style>
